import React, { useEffect, useState } from "react";
import "./HomeScreen.css";
import MobileFilters from "../MobileFilters/MobileFilters";
import { useNavigate } from "react-router-dom";
import { getDoctors } from "../../api/doctor";
import FilterChip from "./components/FilterChip";
import { Skeleton, Typography } from "@mui/material";
import { useAuth } from "../context/AuthContext";
import IsScrolling from "react-is-scrolling";
import { getFormattedAddress, makeQueryStringFromObject } from "../../utils";
import DoctorCardSkeleton from "./components/DoctorCardSkeleton";
import useAppContext from "../context/AppContext";
import ABHAHome from "./ABHAHome/ABHAHome";
import VideoConsultationHome from "./VideoConsultationHome/VideoConsultationHome";
import FeaturedDoctors from "./FeaturedDoctors/FeaturedDoctors";
import HomeLocation from "./HomeLocation/HomeLocation/HomeLocation";
import HomeDocterCard from "./HomeDocterCard/HomeDocterCard";
import HomeDocCard from "./HomeDocterCard/HomeDocCard";

export const initialState = {
    price: "",
    location: 0,
    speciality: [],
    sortBy: "",
    search: "",
};

const HomeScreen = (isScrolling) => {
    const { isAuthenticated, user } = useAuth();
    const { deviceLocation, setSelectedLocation, tempSelectedLocation } =
        useAppContext();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [doctors, setDoctors] = useState([]);
    const [resp, setRespoense] = useState([]);
    const [clinics, setClinics] = useState([]);
    const [doctorId, setDoctorId] = useState("");
    const [loading, setLoading] = useState(false);
    const [loadingLocation, setLoadingLocation] = useState(false);
    const [isLocationDialogOpen, setIsLocationDialogOpen] = useState(false);
    useEffect(() => {
        if (isScrolling) {
            setIsLocationDialogOpen(false);
        }
    }, [isScrolling]);
    //State variables for Filters
    const [price, setPrice] = useState(initialState.price);
    const [location, setLocation] = useState(initialState.location);
    const [speciality, setSpeciality] = useState(initialState.speciality);
    const [sortBy, setSortBy] = useState(initialState.sortBy);
    const [search, setSearch] = useState(initialState.search);

    async function fetchDoctors() {
        setLoading(true);
        const priceRange = price?.split("-");
        const minFees = priceRange?.[0];
        const maxFees = priceRange?.[1];

        const filter = {
            radius: location,
            minFees,
            maxFees,
            sortBy,
            specialities: speciality?.join(","),
            search,
        };

        let query = `?latitude=${deviceLocation?.lat}&longitude=${deviceLocation?.lng}`;
        query = makeQueryStringFromObject(filter, query);
        // console.log("HomeScreen", query);

        const responseData = await getDoctors(query);
        // console.log("fetchDoctors", query, responseData?.data);
        if (responseData) {
            let doctorList = [];
            responseData?.data?.map((x) => {
                if (x.type == "HOSPITAL") {
                    let filterData =
                        x?.doctors?.filter(
                            (z) => !doctorList.some((y) => y._id === z._id)
                        ) || [];
                    doctorList = [...doctorList, ...filterData];
                } else {
                    if (
                        !doctorList.some((y) => y?._id === x?.doctor?._id) &&
                        x?.doctor
                    ) {
                        doctorList.push(x.doctor);
                    }
                }
            });
            setDoctors(doctorList || []);
            setRespoense(responseData?.data || []);
        }
        setLoading(false);
    }

    async function handleDeviceLocation() {
        setLoadingLocation(true);
        if (deviceLocation?.lat && deviceLocation?.lng) {
            const formattedAddress = await getFormattedAddress(deviceLocation);
            setSelectedLocation({
                coordinates: deviceLocation,
                place: formattedAddress,
            });
            setLoadingLocation(false);
        }
    }

    useEffect(() => {
        if (tempSelectedLocation?.place?.name == "Error") {
            handleDeviceLocation();
        } else {
            console.log("not required");
        }
    }, [deviceLocation]);

    useEffect(() => {
        fetchDoctors();
    }, [price, location, speciality, sortBy, deviceLocation, search]);

    function onClickBack(doctorId) {
        setDoctorId(doctorId);
        const clinic = [];
        resp.map((x) => {
            if (
                x.type == "HOSPITAL" &&
                x.doctors?.some((x) => x._id === doctorId)
            ) {
                clinic.push(x);
            }
            if (x.type !== "HOSPITAL" && x.doctor?._id === doctorId) {
                clinic.push(x);
            }
        });
        console.log(clinic);
        setClinics(clinic);
    }

    return (
        <div id="layout">
            {/* Location Bar */}
            <div className="home_profile_header d-flex justify-content-between align-items-start">
                {loadingLocation ? (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                        }}
                    >
                        <Skeleton
                            variant="circular"
                            width={30}
                            height={30}
                            sx={{ marginRight: "10px" }}
                        />
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-around",
                                flexDirection: "column",
                                height: "2rem",
                            }}
                        >
                            <Skeleton
                                variant="rectangular"
                                width={50}
                                height={15}
                            />
                            <Skeleton
                                variant="rectangular"
                                width={90}
                                height={8}
                            />
                        </div>
                    </div>
                ) : (
                    <HomeLocation
                        setIsLocationDialogOpen={setIsLocationDialogOpen}
                        isLocationDialogOpen={isLocationDialogOpen}
                        setLoadingLocation={setLoadingLocation}
                    />
                )}

                <div>
                    {isAuthenticated ? (
                        <svg
                            onClick={() => navigate("/myprofile")}
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                        >
                            <circle cx="15" cy="15" r="12" fill="#D9D9D9" />
                            <path
                                d="M27.5 15C27.5 8.1125 21.8875 2.5 15 2.5C8.11248 2.5 2.49998 8.1125 2.49998 15C2.49998 18.625 4.06248 21.8875 6.53749 24.175C6.53749 24.1875 6.53748 24.1875 6.52498 24.2C6.64998 24.325 6.79998 24.425 6.92498 24.5375C6.99999 24.6 7.06249 24.6625 7.13749 24.7125C7.36249 24.9 7.61249 25.075 7.84999 25.25L8.09999 25.425C8.33749 25.5875 8.58749 25.7375 8.84999 25.875C8.93749 25.925 9.03749 25.9875 9.12499 26.0375C9.37499 26.175 9.63749 26.3 9.91249 26.4125C10.0125 26.4625 10.1125 26.5125 10.2125 26.55C10.4875 26.6625 10.7625 26.7625 11.0375 26.85C11.1375 26.8875 11.2375 26.925 11.3375 26.95C11.6375 27.0375 11.9375 27.1125 12.2375 27.1875C12.325 27.2125 12.4125 27.2375 12.5125 27.25C12.8625 27.325 13.2125 27.375 13.575 27.4125C13.625 27.4125 13.675 27.425 13.725 27.4375C14.15 27.475 14.575 27.5 15 27.5C15.425 27.5 15.85 27.475 16.2625 27.4375C16.3125 27.4375 16.3625 27.425 16.4125 27.4125C16.775 27.375 17.125 27.325 17.475 27.25C17.5625 27.2375 17.65 27.2 17.75 27.1875C18.05 27.1125 18.3625 27.05 18.65 26.95C18.75 26.9125 18.85 26.875 18.95 26.85C19.225 26.75 19.5125 26.6625 19.775 26.55C19.875 26.5125 19.975 26.4625 20.075 26.4125C20.3375 26.3 20.6 26.175 20.8625 26.0375C20.9625 25.9875 21.05 25.925 21.1375 25.875C21.3875 25.725 21.6375 25.5875 21.8875 25.425C21.975 25.375 22.05 25.3125 22.1375 25.25C22.3875 25.075 22.625 24.9 22.85 24.7125C22.925 24.65 22.9875 24.5875 23.0625 24.5375C23.2 24.425 23.3375 24.3125 23.4625 24.2C23.4625 24.1875 23.4625 24.1875 23.45 24.175C25.9375 21.8875 27.5 18.625 27.5 15ZM21.175 21.2125C17.7875 18.9375 12.2375 18.9375 8.82499 21.2125C8.27499 21.575 7.82499 22 7.44999 22.4625C5.481 20.4778 4.37579 17.7957 4.37498 15C4.37498 9.1375 9.13749 4.375 15 4.375C20.8625 4.375 25.625 9.1375 25.625 15C25.625 17.9 24.45 20.5375 22.55 22.4625C22.1875 22 21.725 21.575 21.175 21.2125Z"
                                fill="#C1C1C1"
                            />
                            <path
                                d="M15 8.66235C12.4125 8.66235 10.3125 10.7624 10.3125 13.3499C10.3125 15.8874 12.3 17.9499 14.9375 18.0249H15.1625C16.3759 17.985 17.5263 17.475 18.3706 16.6026C19.215 15.7303 19.6872 14.5639 19.6875 13.3499C19.6875 10.7624 17.5875 8.66235 15 8.66235Z"
                                fill="#C1C1C1"
                            />
                        </svg>
                    ) : (
                        <button
                            onClick={() => navigate("/login")}
                            className="home_login_button"
                        >
                            Log In
                        </button>
                    )}
                </div>
            </div>

            {/* Search Bar */}
            <div className="home_search_input_container">
                <input
                    type="text"
                    className="home_search_input"
                    placeholder="Search doctors by name or location..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
                <button
                    onClick={() => setOpen(true)}
                    className="home_input_filter_btn"
                >
                    <svg width="16" height="16" viewBox="0 0 14 14" fill="none">
                        <rect width="14" height="14" fill="url(#pattern0)" />
                        <defs>
                            <pattern
                                id="pattern0"
                                patternContentUnits="objectBoundingBox"
                                width="1"
                                height="1"
                            >
                                <use
                                    xlinkHref="#image0_468_292"
                                    transform="scale(0.0111111)"
                                />
                            </pattern>
                            <image
                                id="image0_468_292"
                                width="90"
                                height="90"
                                xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAABmJLR0QA/wD/AP+gvaeTAAADdklEQVR4nO3az0sUcRjH8c/z3VG0Sx6qi6gs/guZBRJ1TboEbpE/TkFSp4JIlyU2MgsKj9Gli7WxsdeiQwVBHirtX4htjSA91C1rx3m6rBUpuuPOd+aZL8/ruM58fXhYZhd9A0oppZRSSimllFLuoKQHsIqZcrOfjoEwTkRDzOhu/OAz2CwYs/6wPJ19Hccozi565EY1Sxl6AOD4dtcx8CpDwbnydLZqcx4nFz0ys3yIKHgGwr7m7uBVE5gT5ULvkq2ZnFv0qZmPfZ4xiwDtD3nrCht/oDLVX7Mxl7FxaJI8Y+7vYskAcIDYuxf5QA1OvaNP36oNMPP7Vs6gIDjypJB9G9VMf85t5qKTxS97Ott+3QHRKIC9UQ8Rg58Mynvsl/w2IvJpDKCbANpbOPM7QCXuoCuVyz0/drrYa+bEzvb6HEDnWxgqUQwUK/neuX9eupubrbUDfLOFY7sAvkhr7AGY3OniHZ/RxSIbABMtDJQ84z/+/yU/8EsRnT7e2NH2I0T0y1In4zHH+fuaeEdTAGA+hlmsIfZGN73IbeORHM6Yb+xoW009o7nDXDJrXGfwGICuloeLG6OYm63WfUYJADLAGDFfa/HUbwAecae52szFTn29OzNTOxgYXmzlDANzuJzveRfVTH/PdUi50LvERM93ez8DT20sGXBs0QAAqk8CWNnFnV8zgXch6nE2OLfoylR/zQQ0DPBqiNtWmDBcLnQv25rLuUUDjUfIOgYBvGzi8heGgsHKdN8HmzM59WG4ldzt2lEKMMHEQ2D0AAABNWYsGKL5cr73TdIzKqWUUkpFJ9mvd4K6C9sSW7S07sK2RBYtsbuwLfZFS+0ubIv9bx1SuwvbYn1HS+4ubJPSddjoLmxLX9dhqbuwLYVdh93uwrZ0dx1xdxe2yeg6Au/sptei6i5sS1PXQcD13GzVj7i7sE1u1yG5u7At1me05O7Ctvg/DIV2F7bFvmip3YVtiXy9k9hd2JZ416HdhVJKKaVUlBL/ehdKijuQ1Cw67R1IKhbtQgciftGudCBi/5W1wZUORPQ72qUORErXEZaEDiR9XUdYQjqQFHYdYcnqQNLddYQlvQOR0XWEJakDSVPXEZaQDkRu1xGWSx2I6Ge0Sx2I6EUDcKYDEb9oVzoQ8YsG3OhARH8YbkU7EKWUUkoppZRSSqnk/Qa/yQUtVyL9swAAAABJRU5ErkJggg=="
                            />
                        </defs>
                    </svg>
                </button>

                <MobileFilters
                    open={open}
                    setOpen={setOpen}
                    price={price}
                    setPrice={setPrice}
                    location={location}
                    setLocation={setLocation}
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                    speciality={speciality}
                    setSpeciality={setSpeciality}
                />
            </div>

            {/* Filter Chips */}
            <div className="filter_chips">
                {price?.length > 0 && (
                    <FilterChip
                        label="Fees"
                        setFilter={setPrice}
                        initialState={initialState.price}
                    />
                )}
                {location > 0 && (
                    <FilterChip
                        label="Location"
                        setFilter={setLocation}
                        initialState={initialState.location}
                    />
                )}
                {speciality?.length > 0 && (
                    <FilterChip
                        label="Speciality"
                        setFilter={setSpeciality}
                        initialState={initialState.speciality}
                    />
                )}
                {sortBy?.length > 0 && (
                    <FilterChip
                        label="Sort By"
                        setFilter={setSortBy}
                        initialState={initialState.sortBy}
                    />
                )}
            </div>

            {/* ABHA Card */}
            {/* {user?.healthIdNumber ? null : <ABHAHome />} */}

            {/* Video Consultation Hidden */}

            {/* <VideoConsultationHome /> */}

            {/* Featured Doctors Hidden*/}

            {/* <FeaturedDoctors /> */}

            {/* Doctors Around You */}
            <div className="home_feature_docters">
                <h1 className="home_feature_docters_header new_11_sep">
                    Doctors Around You
                </h1>
                {loading ? (
                    Array(10)
                        .fill("_")
                        .map((_, i) => <DoctorCardSkeleton key={i} />)
                ) : !doctorId && doctors?.length ? (
                    doctors?.map((doctor, index) => (
                        <HomeDocCard
                            doctor={doctor}
                            key={index}
                            onClickBack={onClickBack}
                        />
                    ))
                ) : doctorId && clinics?.length ? (
                    clinics?.map((doctor, index) => (
                        <HomeDocterCard
                            doctor={doctor}
                            key={index}
                            doctorId={doctorId}
                            doctorDetails={doctors.find(
                                (x) => x._id == doctorId
                            )}
                        />
                    ))
                ) : (
                    <div style={{ margin: "2rem" }}>
                        <Typography variant="title">
                            Doctors not found!
                        </Typography>
                    </div>
                )}
            </div>
        </div>
    );
};

export default IsScrolling(HomeScreen);
